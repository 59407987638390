import React, { Component } from 'react';

export default class Footer extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <footer className="main-footer">
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12 col-6">
                    <div className="footer-widget about-widget">
                      <div className="logo-outer">
                        <div className="logo">
                          <a href="/"><img src="/img/logo2.png" alt="" title="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="footer-column col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <br />
                      <br />
                      <h2>Oficinas Monterrey, Nuevo León.</h2>
                      <ul className="footer-link-list">
                        <li>Gladiola 133 Col. Cuauhtémoc, San Nicolás de la Garza.</li>
                        <li>C.P. 66450. Tel:  (81) 8334 9006</li>
                      </ul>
                    </div>
                  </div>
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget services-widget">
                      <br />
                      <br />
                      <h2>Oficinas Culiacán, Sinaloa.</h2>
                      <ul className="footer-link-list">
                        <li>Río Piaxtla 245-A Ote, Colonia Guadalupe. Culiacán, Sin.</li>
                        <li>C.P. 80220. Tel: (667) 712 8811</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="clearfix">
              <div className="pull-left">
                <div className="copyright"><span></span></div>
              </div>
              <div className="pull-right">
                <ul className="footer-list">
                  <li><a href="https://a15ac7ca-2754-4441-bab8-22d7d44dfcbf.filesusr.com/ugd/6041d6_d91e7d3f85104370b6d1c27118e1b8d8.pdf" target="_blank" rel="noreferrer" >Aviso de Privacidad</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}
