import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { RowsNotFound } from 'stateless';
import moment from 'moment';
import { postRequest, URL_FILES } from 'utils/api';
import { DivFormGroup } from 'controls';
import { Pagination, Modal } from 'react-bootstrap';

export default class TableOrdenesServicios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsTotal: 100,
      activePage: 1,
      pagination: 20,
      fechaInicio: moment().add(-1,'year').format('YYYY-MM-DD'),
      fechaFin: moment().format('YYYY-MM-DD'),
      list: [],
      showModal: false,
      email: '', 
      checked: false, 
      selected: 'descarga'
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { hashId } = this.props;

    postRequest({
      url: 'edo',
      body: {
        hashId
      },
      success: ({ data }) => {
        let email = data.email;
        if(!email && data.contactos && data.contactos.length > 0){
          email = data.contactos[0].email;
        }
        this.setState({
          data,
          email
        }, this.handleSearch );
      },
      fail: (error, status, data) => {
        console.log('fail', error, status, data);
        this.setState({ load: true });
        location.href = `/`;
      }
    });
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, this.handleSearch);
  }

  handleSearch() {
    const { pagination, fechaInicio, fechaFin, activePage } = this.state;
    postRequest({
      url: `edo/ordenes-servicios/search?page=${activePage}&limit=${pagination}`,
      body: {
        fechaFin: fechaFin,
        fechaInicio: fechaInicio,
        hashId: this.props.hashId,
      },
      success: ({ data, count }) => {
        this.setState({
          list: data.map((item)=> ({...item, checked:false})),//checkbox
          rowsTotal: count,
          loading: false,
        });
      },
      fail: () => {},
    });
  }

  onClickDescargarPDF(id){
    this.setState({ loading: true },()=>{
      postRequest({
        url: 'ordenes-servicios/pdf',
        body: {
          id, hashId: this.props.hashId
        },
        success: ({ data: { url } }) => {
          window.open(URL_FILES + url, '_blank');
        },
        fail: () => {},
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }

  onClickDescargarXML(id){
    this.setState({ loading: true },()=>{
      postRequest({
        url: 'ordenes-servicios/xml',
        body: {
          id, hashId: this.props.hashId
        },
        success: ({ data: { url } }) => {
          window.open(URL_FILES + url, '_blank');
        },
        fail: () => {},
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }

  onChangeCheckbox(index){
    this.setState({
      list: this.state.list.map((item,i)=> {
        //i === index ? !item : item;
        if(i === index){
          item.checked = !item.checked; //click en input --> cambia a estado contrario
        }
        return item;
      })
    });

  }

  onChangeAllCheckbox(e){
    this.setState({
      list: this.state.list.map((item)=> {
        //i === index ? !item : item;
        item.checked = e.target.checked; //click en input --> cambia a estado contrario
        return item;
      }), 
      checked: e.target.checked
    });

  }

  renderRows() {
    const { list } = this.state;

    return list.map((r, i) => {
      const {  numero_orden_servicio, fecha_solicitud, descripcion, checked, estatus_servicios } = r;

      return (
        <tr key={i}>
          <td className='text-center' width="5%">
            <input type="checkbox" checked={checked} onChange={this.onChangeCheckbox.bind(this, i)} />
          </td>
          <td>
            {numero_orden_servicio}
          </td>
          <td>{moment(fecha_solicitud).format('DD/MM/YYYY')}</td>
          <td>{ descripcion }</td>
          <td>{ estatus_servicios }</td>
          {/* <td>
            <button className="btn btn-primary mr-5" onClick={ this.onClickDescargarPDF.bind( this, _id) }> PDF </button>
          </td> */}
        </tr>
      );
    });
  }

  handleSelectPagination(eventKey) {
    this.setState({ activePage: eventKey }, this.handleSearch);
    /*const page = (eventKey * this.state.pagination) - this.state.pagination;
    this.loadListLogs({ rank: page, pagination: this.state.pagination });*/
  }

  close(){
    this.setState({showModal: false});
  }

  handleEnviar(e){
    e.preventDefault();
    const { selected, email } = this.state;
    const facturas = this.state.list
      .filter( item => item.checked)
      .map( item => item._id);
    this.setState({ loading: true },()=>{
      postRequest({
        url: 'zip',
        body: {
          facturas, hashId: this.props.hashId, selected, email
        },
        success: ( { data: { url } } ) => {
          if(selected == 'descarga'){
            window.open(URL_FILES + url, '_blank');
          } else {
            this.props.notification({ type: 'warning', message: 'Correo enviado con éxito', title: 'Aviso'});
          }
        },
        fail: () => {},
        finally: () => {
          this.setState({ loading: false });
        }
      });
    });
  }

  onClickEnviar(){
    if(this.state.list.filter( item => item.checked).length == 0)
      return this.props.notification({ type: 'warning', message: 'Es necesario seleccionar por lo menos una factura', title: 'Aviso'});
    this.setState({showModal: true});
  }

  onChangeEmail(e){
    this.setState({email: e.target.value});
  }

  onChangeRadio(e){
    this.setState({selected: e.target.value});
  }

  render() {
    const { list, rowsTotal, pagination, activePage, fechaInicio, fechaFin, showModal, email } = this.state;
    const rows =
      list.length > 0 ? (
        this.renderRows()
      ) : (
        <RowsNotFound
          message="No cuenta con facturas por pagar en este momento."
          colSpan={7}
        />
      );

  
    const pageTotal =
      list.length > 0 ? Math.ceil(rowsTotal / pagination) : null;
    //console.log(pageTotal);

    return (
      <Fragment>
        <Modal show={showModal} onHide={this.close.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Envio de correo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleEnviar.bind(this)}>
              <div className="btns checkbox">
                <label htmlFor="descarga">
                  <input type="radio" checked={this.state.selected=='descarga'} name="selected" value="descarga" id="descarga" onChange={this.onChangeRadio.bind(this)} />  Descargar
                </label>
                <label htmlFor="enviar">
                  <input type="radio" checked={this.state.selected=='enviar'} name="selected" value="enviar" id="enviar" onChange={this.onChangeRadio.bind(this)} /> Enviar
                </label> 
              </div>
              {this.state.selected=='enviar' && (
                <div>
                  <p className="lead">
                    Proporciona un correo electrónico 
                  </p>
                  <div className="row">
                    <div className="col-sm-12">
                      <DivFormGroup>
                        <input value={email} onChange={this.onChangeEmail.bind(this)} placeholder="Ingresa correo electrónico" className="form-control" />
                      </DivFormGroup>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success pull-right" onClick={this.handleEnviar.bind(this)}>Aceptar</button>
            <button onClick={this.close.bind(this)} className="btn btn-default pull-right mr-5" type="button">Cerrar</button>
          </Modal.Footer>
        </Modal>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="row ">
              <div className="col-md-6"> 
                {/* <button className="btn btn-primary mr-5" onClick={this.onClickEnviar.bind(this)} > ENVIAR </button> */}
              </div>
              <div className="col-md-3">
                <input
                  type="date"
                  className="form-control"
                  value={fechaInicio}
                  onChange={this.onChange}
                  name="fechaInicio"
                />
              </div>
              <div className="col-md-3">
                <input
                  type="date"
                  className="form-control"
                  value={fechaFin}
                  onChange={this.onChange}
                  name="fechaFin"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="table-outer">
          <table className="cart-table">
            <thead className="cart-header">
              <tr>
                <th scope="col" className='text-center' width="5%">
                  <input type="checkbox" checked={this.state.checked} onChange={this.onChangeAllCheckbox.bind(this)} />
                </th>
                <th scope="col">Número de Orden</th>
                <th scope="col">Fecha</th>
                <th scope="col">Descripción</th>
                <th scope="col">Estado</th>
                {/* <th scope="col">Acciones</th> */}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className="text-center">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            maxButtons={3}
            items={pageTotal}
            activePage={activePage}
            onSelect={this.handleSelectPagination.bind(this)}
          />
        </div>
      </Fragment>
    );
  }
}

TableOrdenesServicios.propTypes = {
  hashId: PropTypes.string.isRequired,
  notification: PropTypes.func.isRequired,
};
